import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default ({ color = "black", className }) => {
  const data = useStaticQuery(graphql`
    query {
      blackLogo: file(relativePath: { eq: "dt_logo_black.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      whiteLogo: file(relativePath: { eq: "dt_logo_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  if (color === "black") {
    return (
      <Img fluid={data.blackLogo.childImageSharp.fluid} className={className} />
    );
  } else {
    return (
      <Img fluid={data.whiteLogo.childImageSharp.fluid} className={className} />
    );
  }
};
