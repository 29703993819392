import React from "react";
import DTLogo from "../components/logo";

export default () => (
  <header className="w-full px-6 py-4 md:px-10 bg-white border-b border-gray-300">
    <div className="flex items-center">
      <DTLogo color="black" className="w-16 md:w-20" />
    </div>
  </header>
);
