import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import Footer from "./footer";

export default ({ children, isIndexPage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  if (isIndexPage) {
    return (
      <div className="min-h-screen">
        <main className="flex flex-col min-h-screen">{children}</main>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className="flex flex-col min-h-screen">{children}</main>
        <Footer />
      </div>
    );
  }
};
